<template>
  <apex-chart
    type="donut"
    :height="height"
    :options="options"
    :series="series"
  />
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configuration for apex-charts
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "GenderSplit",

  // Register the components
  components: {
    ApexChart
  },

  // Accept incoming data from parent
  props: {
    audienceData: {
      type: Object,
      required: true
    },

    showLegend: {
      type: Boolean,
      required: false,
      default: true
    },

    height: {
      type: [String, Number],
      required: false,
      default: 322
    }
  },

  // Define readonly computable properties
  computed: {
    /**
     * Get the series data for chart
     *
     * @returns {Array}
     */
    series() {
      // reversing to make sure male is first and female later
      return this.audienceData?.gender.map((v) => v?.weight * 100)
    },

    /**
     * Get the chart's configurations object
     *
     * @returns {Object}
     */
    options() {
      return merge(apexChartBaseOptions(), {
        labels: this.audienceData?.gender.map((v) => `${capitalizeString(v.code)}: ${numeral.fPercent(v.weight * 100)}`),
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: [
          "#f05d56",
          "#2196F3"
        ],
        stroke: { colors: ["#fff"] },
        legend: {
          show: this.showLegend,
          floating: false,
          horizontalAlign: "center",
          position: "top",
          labels: {
            // colors: ["#000000"],
            colors: [host.theme.color.text],
            useSeriesColors: false,
          },
          offsetY: 20,
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: true,
            opacity: 0.1
          },
          style: {
            fontSize: "13px",
            colors: ["#000", "#000"]
          }
        },
        tooltip: {
          fillSeriesColor: false,
          y: {
            formatter: (val) => numeral.fPercent(val),
            title: {
              formatter: (seriesName) => seriesName.split(":")[0] + ':'
            }
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.8,
            startAngle: 180,
            endAngle: 180 + 360,
            donut: {
              labels: {
                show: false
              },
              value: {
                show: false
              }
            },
          },
        },
      })
    }
  }
}
</script>
